import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Input, Table, Tag, Button, Space, DatePicker, Row, Col, Card, Statistic, Drawer, List, Spin } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import ExecutionsStore from "./ExecutionsStore";
import { JsonEditor as Editor } from "jsoneditor-react";
import AutomatePitchPageContent from "../AutomatePitchPageContent";
import dayjs from "dayjs";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { PieChart } from "../../../Analyze/Dashboards/Library";
import "./List.css";
import { observer } from "mobx-react";
import BookmarksStore from "../../../Common/Bookmarks/BookmarksStore";
import { themeConfig } from "../../../Common/Layout/config";
import GraphQlService from "../../../Common/GraphQlService";

const StatusTag = ({ status, t }) => {
  switch (status) {
    case "Error":
      return <Tag color="red">{t("automate.error")}</Tag>;
    case "Warning":
      return <Tag color="orange">{t("automate.warning")}</Tag>;
    default:
      return <Tag color="green">{t("automate.success")}</Tag>;
  }
};

const formatSeconds = sec => {
  sec = sec * 1000;
  const hours = moment.duration(sec).hours();
  const minutes = moment.duration(sec).minutes();
  const seconds = moment.duration(sec).seconds();

  let time = "";

  if (!sec) return "-";

  if (hours > 0) time += `${hours}h `;
  if (minutes > 0) time += `${minutes}min `;
  if (seconds > 0) time += `${seconds}s`;

  return time;
};

const AutomateWorkflowsExecutionsList = ({ t }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [currentExecution, setCurrentExecution] = useState(null);
  const [highlightedRow, setHighlightedRow] = useState(null);

  const currentPlan = localStorage.getItem(`plan`);
  const { currentTheme } = themeConfig();

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchedColumn("name");
    setSearchText(selectedKeys[0]);
  };

  // reload table every minute or so
  useEffect(() => {
    ExecutionsStore.getData();
    const timer = setInterval(async () => {
      await ExecutionsStore.getData();
    }, 60 * 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (["bas", "pro", "col"].includes(currentPlan)) {
    return <AutomatePitchPageContent />;
  }

  const pagination = {
    pageSize: ExecutionsStore.pageSize,
    current: ExecutionsStore.page,
    total: ExecutionsStore.header.total,
    hideOnSinglePage: true,
    showQuickJumper: true,
    showSizeChanger: true,
    onChange: ExecutionsStore.tableChange
  };

  const columns = [
    {
      title: " ",
      key: "highlight",
      dataIndex: "id",
      render: id => {
        return (
          <div style={{ width: 5, height: 25 }}>
            {currentExecution && currentExecution.id === id ? (
              <div style={{ backgroundColor: "rgba(237, 205, 31, 0.9)", height: "110%" }}></div>
            ) : null}
            {highlightedRow && highlightedRow === id ? (
              <div style={{ backgroundColor: "#c7c7c7", height: "110%" }}></div>
            ) : null}
          </div>
        );
      }
    },
    {
      title: t("automate.executionName"),
      key: "WorkflowName",
      render: item =>
        searchedColumn !== "name" ? (
          item.payload.Bpmn.WorkflowName
        ) : (
          <Highlighter
            highlightStyle={{ backgroundColor: "#fecc3b", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={item.payload.Bpmn.WorkflowName}
          />
        ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value, record) =>
        record.payload.Bpmn.WorkflowName.toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search Workflow Name`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
              size="small"
            >
              {t("automate.search")}
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
              {t("automate.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
                setSearchedColumn("name");
              }}
            >
              {t("automate.filter")}
            </Button>
          </Space>
        </div>
      )
    },
    {
      title: t("automate.executionTime"),
      key: "ExecutionTime",
      render: t => formatSeconds(t.payload.ExecutionDurationInSeconds),
      sorter: (a, b) => a.payload.ExecutionDurationInSeconds || 0 - b.payload.ExecutionDurationInSeconds || 0
    },
    {
      title: t("automate.exectionStartDateTime"),
      key: "createdDateTime",
      render: t => (
        <>
          {moment(t.createdDateTime).fromNow()}
          &nbsp;
          <small>{moment(t.createdDateTime).format("LLL")}</small>
        </>
      ),
      onFilter: (value, record) => moment(record.createdDateTime).isBetween(value[0], value[1], "day", "[]"),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e ? [e] : [])}
            onPressEnter={() => {
              confirm();
              setSearchText(selectedKeys[0]);
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
              size="small"
            >
              {t("automate.search")}
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
              {t("automate.reset")}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                setSearchText(selectedKeys[0]);
              }}
            >
              {t("automate.filter")}
            </Button>
          </Space>
        </div>
      )
    },
    {
      title: t("automate.status"),
      key: "status",
      dataIndex: "status",
      render: status => <StatusTag status={status} t={t} />,
      filters: [
        {
          text: t("automate.success"),
          value: "Success"
        },
        {
          text: t("automate.warning"),
          value: "Warning"
        },
        {
          text: t("automate.error"),
          value: "Error"
        }
      ],
      onFilter: (value, record) => record.status === value
    }
  ];

  return (
    <>
      <Row gutter={15}>
        <Col xs={24} sm={24} md={8} style={{ marginBottom: 15 }}>
          <Card loading={ExecutionsStore.isLoading} style={{ height: "100%" }}>
            <Statistic title={t("automate.NumOfJobs")} value={ExecutionsStore.header.total} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ marginBottom: 15 }}>
          <Card loading={ExecutionsStore.isLoading} style={{ height: "100%" }}>
            <Statistic title={t("automate.timeSaved")} value={ExecutionsStore.header.timeSaved} />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ marginBottom: 15 }}>
          <Card loading={ExecutionsStore.isLoading}>
            <Row>
              <Col span={8}>
                <Statistic title={t("automate.status")} value={" "} />
              </Col>
              <Col span={16}>
                <PieChart
                  data={JSON.parse(ExecutionsStore.header.status || "[]")}
                  width={250}
                  height={80}
                  colors={["key", ["#b7eb8f", "#EA990F", "#de3a3a"]]}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={ExecutionsStore.isLoading}
            dataSource={ExecutionsStore.executions}
            columns={columns}
            pagination={pagination}
            size="small"
            className={currentTheme === "light" ? "ant-table-beawre" : "ant-table-beawre-dark"}
            rowKey={record => `execution_${record.id}`}
            onRow={record => {
              return {
                onClick: () => {
                  setCurrentExecution(record);
                },
                onMouseEnter: () => {
                  setHighlightedRow(record.id);
                },
                onMouseLeave: () => {
                  setHighlightedRow(null);
                }
              };
            }}
          />
        </Col>
      </Row>

      {currentExecution && <AutomateWorkflowsExecutionDetails data={currentExecution} setCurrentExecution={setCurrentExecution} t={t} />}
    </>
  );
};

export const AutomateWorkflowsExecutionDetails = ({ data, setCurrentExecution, t }) => {
  const [executionLogs, setExecutionLogs] = useState(undefined);
  const [executionLogsJsonEditor, setExecutionLogsJsonEditor] = useState();
  const [jsonEditor, setJsonEditor] = useState();
  const [dataDetails, setDataDetails] = useState();

  useEffect(() => {
    loadExecutionLogs();

    if (!data.payload)
      loadExecution();
  }, []);

  const loadExecution = async () => {
    const graphQlService = new GraphQlService();
    const r = await graphQlService.get(`{ automateExecution(id: "${data.id}") { id rootId createdDateTime payload } }`);
    setDataDetails(ExecutionsStore.parsePayload(r.data.automateExecution));
  };


  const loadExecutionLogs = () => {
    setExecutionLogs(undefined);
    const graphQlService = new GraphQlService();
    graphQlService
      .get(`{ automateExecutionLogs(id: "${data.id}") }`)
      .then(r => setExecutionLogs(JSON.parse(r.data.automateExecutionLogs || "{}")));
  };

  console.log('dataDetails', dataDetails)
  if (!dataDetails)
    return <Spin></Spin>;

  return (<Drawer
    title={
      <h4 style={{ maxWidth: "90%" }}>
        {t("automate.exectuionDetailsOf")} {dataDetails ? dataDetails?.payload?.Bpmn?.WorkflowName : ""}
      </h4>
    }
    open={true}
    placement="right"
    destroyOnClose={true}
    height="auto"
    width={"52%"}
    style={!BookmarksStore.showBookmarks ? { paddingTop: 48 } : { paddingTop: 48, marginTop: 35 }}
    maskStyle={{ backgroundColor: "transparent" }}
    closeIcon={<CloseOutlined />}
    onClose={() => {
      setDataDetails(undefined);
      setCurrentExecution(undefined)
    }}
  >
    <div>
      {dataDetails?.status === "Warning" && (
        <div>
          <h3>Warnings</h3>
          <List
            size="small"
            dataSource={dataDetails?.warnings}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  executionLogsJsonEditor.collapseAll();
                  if (!executionLogsJsonEditor.node.expanded) executionLogsJsonEditor.node.expand(false);
                  executionLogsJsonEditor.expand({ path: [item.parent], isExpand: true });
                  executionLogsJsonEditor.expand({ path: ["Warnings"], isExpand: true });
                }}
              >
                {index + 1}) {item.title}
              </List.Item>
            )}
          />
        </div>
      )}
      <h3>Execution definition</h3>
      <div style={{ maxHeight: 200 }}>
        {dataDetails && <Editor
          value={dataDetails.payload}
          mode="view"
          ref={e => {
            if (e) setJsonEditor(e.jsonEditor);
          }}
        />}
      </div>

      <h3 style={{ marginTop: 50 }}>Execution details</h3>
      {!executionLogs && (
        <div style={{ textAlign: "center", width: "100%", marginTop: 30 }}>
          <Spin />
        </div>
      )}
      {executionLogs && (
        <Editor
          value={executionLogs}
          mode="view"
          ref={e => {
            if (e) setExecutionLogsJsonEditor(e.jsonEditor);
          }}
        />
      )}
    </div>
  </Drawer>);
}

export default withTranslation()(observer(AutomateWorkflowsExecutionsList));
