import React from 'react'
import { withTranslation } from "react-i18next";
import { Button, Col, Row, Tag } from 'antd';
import { BranchesOutlined, DeploymentUnitOutlined, NodeIndexOutlined, SplitCellsOutlined } from '@ant-design/icons';
import { themeConfig } from '../../Common/Layout/config';

const AutomatePitchPageContent = ({ t }) => {
    const { currentTheme } = themeConfig();

    return (
        <>
            <div style={(currentTheme === "dark") ? { marginTop: 62, height: "100%", display: "flex", flexDirection: "column", color: "#FFFFFF" } : { marginTop: 62, backgroundColor: "white", height: "100%", display: "flex", flexDirection: "column", borderRadius: 15 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <h1 style={{ fontSize: "2rem" }}>{t("mainMenu.automate")}</h1>
                    <Tag
                        color="#f3ca27"
                        style={{
                            width: 120,
                            height: 30,
                            borderRadius: 30,
                            marginLeft: "25px",
                            martinTop: "25px",
                            padding: "-1px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <span style={{ fontSize: "14px" }}>{t("mainMenu.upgrade")}</span>
                    </Tag>
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2 style={{ marginBottom: 30 }}>{t("featureLockedMessage")}</h2>
                    <img style={{ width: 500, height: "auto", marginTop: 20, marginBottom: 40 }} src='automate-pitchpage.png' alt='Automate' />
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ width: "60%", textAlign: "center" }}>
                        <h1 style={{ marginTop: 60 }}>{t("automatePitchPage.title1")}</h1>
                        <h3>{t("automatePitchPage.paragraph1")}</h3>
                    </div>
                    <div style={{ width: "60%", textAlign: "center", marginTop: 40 }}>
                        <h1 style={{ marginTop: 60 }}>{t("automatePitchPage.title2")}</h1>
                        <h3>{t("automatePitchPage.paragraph2")}</h3>
                    </div>

                    <Row justify='center' align='middle' gutter={20} style={{ width: "60%", marginTop: 90 }}>
                        <Col span={6} style={{ textAlign: "center" }}>
                            <NodeIndexOutlined style={{ fontSize: 40, marginBottom: 20 }} />
                            <h4>{t("automatePitchPage.digitilize")}</h4>
                            <p>{t("automatePitchPage.digitilizeContent")}</p>
                        </Col>
                        <Col span={6} style={{ textAlign: "center" }}>
                            <DeploymentUnitOutlined style={{ fontSize: 40, marginBottom: 20 }} />

                            <h4> {t("automatePitchPage.synchronize")}</h4>
                            <p>{t("automatePitchPage.synchronizeContent")}</p>
                        </Col>
                        <Col span={6} style={{ textAlign: "center" }}>
                            <BranchesOutlined style={{ fontSize: 40, marginBottom: 20 }} />
                            <h4>{t("automatePitchPage.automate")}</h4>
                            <p> {t("automatePitchPage.automateContent")}</p>
                        </Col>
                        <Col span={6} style={{ textAlign: "center" }}>
                            <SplitCellsOutlined style={{ fontSize: 40, marginBottom: 20 }} />
                            <h4>{t("automatePitchPage.dataFragmentation")}</h4>
                            <p>{t("automatePitchPage.dataFragmentationContent")}</p>
                        </Col>
                    </Row>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: 50, marginTop: 60 }}>
                        <Button href="https://www.beawre.com/automate" target="_blank" type='primary'>{t("collectPitchPage.moreInfo")}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(AutomatePitchPageContent)