import { Chart, Coord, Geom, Legend, Tooltip } from "bizcharts_v3";
import React from "react";

const PieChart = ({ data, colors, width = 600, height = 300, scale, padding = "auto", radius = 1, tooltip }) => {
  return (
    <Chart width={width} height={height} data={data} scale={scale} padding={padding} forceFit>
      <Coord type="theta" radius={radius} />
      <Legend position="right-center" />
      {tooltip && <Tooltip />}
      <Geom type="intervalStack" position="percent" color={colors ? colors : "key"} tooltip={tooltip ? tooltip : "key*value"} />
    </Chart>
  );
};

export { PieChart };
